/*
 * Copyright (C) 1998-2020 by Northwoods Software Corporation
 * All Rights Reserved.
 *
 * FloorplanPalette Class
 * A FloorplanPalette is a Palette with special rules
 */

import * as go from "gojs";
import {
  makeDefaultNode,
  makeWindowNode,
  makeMultiPurposeNode,
  makeRoomNode,
  makeDoorNode,
  makePaletteWallNode,
  makeElementNode,
  makeGroundNode,
  makeTextNode,
} from "../../floorPlanner/diagram/floorplan-diagram";
import { environment } from "src/environments/environment";

export class FloorplanPalette extends go.Palette {
  constructor() {
    go.Diagram.licenseKey = environment.goLicense;

    super();

    const $ = go.GraphObject.make;
    this.contentAlignment = go.Spot.Center;
    this.layout = $(go.GridLayout);
    (this.layout as go.GridLayout).wrappingColumn = 1;

    this.addDiagramListener("SelectionDeleted", function (e) {
      console.log(e)
    });

    this.nodeTemplateMap.add("", makeDefaultNode()); // Default Node (furniture)
    this.nodeTemplateMap.add("MultiPurposeNode", makeMultiPurposeNode()); // Multi-Purpose Node
    this.nodeTemplateMap.add("WindowNode", makeWindowNode()); // Window Node
    this.nodeTemplateMap.add("PaletteWallNode", makePaletteWallNode()); // Palette Wall Node
    this.nodeTemplateMap.add("DoorNode", makeDoorNode()); // Door Node
    this.nodeTemplateMap.add("RoomNode", makeRoomNode()); // Room Node

    this.nodeTemplateMap.add("squid", makeElementNode(true)); // Room Node
    this.nodeTemplateMap.add("element", makeElementNode(true)); // Room Node
    this.nodeTemplateMap.add("text", makeTextNode(true)); // Room Node
    this.nodeTemplateMap.add("ground", makeGroundNode()); // Room Node

    // palette also contains "floor" nodes -- nodes of particular
    // floor types that can be dragged and dropped into wall-enclosed areas to create Room Nodes
    this.nodeTemplateMap.add(
      "FloorNode",
      $(
        go.Node,
        "Auto",
        $(
          go.Shape,
          { fill: makeFloorBrush(null), desiredSize: new go.Size(100, 100) },
          new go.Binding("fill", "floorImage", (src) => {
            return makeFloorBrush(src);
          })
        ),
        $(
          go.TextBlock,
          "Drag me out to a wall-enclosed space to create a room",
          { desiredSize: new go.Size(90, NaN) },
          new go.Binding("visible", "", (node: go.Node) => {
            if (node.diagram instanceof go.Palette) {
              return true;
            }
            return false;
          }).ofObject()
        )
      )
    );

    this.toolManager.contextMenuTool.isEnabled = false;

    // add this new FloorplanPalette to the "palettes" field of its associated Floorplan
    // floorplan.palettes.push(this);
  } // end FloorplanPalette constructor
}

/**
 * Make a Pattern brush for floor nodes
 * @param src The relative path of the image to use for the pattern brush. If this is not specified, a default path is tried
 */
function makeFloorBrush(src: string | null) {
  const $ = go.GraphObject.make;
  if (src === null || src === undefined) {
    src = "images/textures/floor1.jpg";
  }
  const floorImage = new Image();
  floorImage.src = src;
  return $(go.Brush, "Pattern", { pattern: floorImage });
}

// export = FloorplanPalette;
