import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import * as go from "gojs";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Floorplan } from "src/app/floorPlanner/diagram/floorplan-diagram";
import { DiagramUnitsAbbreviations, ScalesEnum } from "src/app/floorPlanner/models/elements";
import { convertUnits, convertUnitsToPixels } from "src/app/floorPlanner/tools";
import { IOpenedFolio } from "src/app/folio/folio.service";

type PaperFormatType = { name: string, width: number, height: number, units: DiagramUnitsAbbreviations }
type PaperOrientationType = 'portrait' | 'landscape'

@Component({
  selector: "print-preview",
  templateUrl: "./print-preview.component.html",
})
export class PrintPreviewComponent implements OnInit, OnDestroy {
  @Input() folio!: IOpenedFolio
  @ViewChild('svgHolder', { static: true }) svgHolder!: ElementRef

  form!: FormGroup
  formats: PaperFormatType[] = [
    { name: 'A4', width: 210, height: 297, units: DiagramUnitsAbbreviations.mm },
    { name: 'A3', width: 297, height: 410, units: DiagramUnitsAbbreviations.mm }
  ]
  orientations: PaperOrientationType[] = ['portrait', 'landscape']
  scales = Object.keys(ScalesEnum).filter(k => isNaN(Number(k)));
  scalesEnum = ScalesEnum

  destroy$ = new Subject()

  constructor(private fb: FormBuilder, private modal: BsModalRef, private renderer: Renderer2) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      format: [this.formats[1]],
      orientation: [this.orientations[1]],
      scale: [ScalesEnum[ScalesEnum.centieme]],
    })

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(value => {
        this.getFolioSvg(value);
      })
    this.getFolioSvg(this.form.value);
  }

  getFolioSvg(value: { format: PaperFormatType, scale: keyof typeof ScalesEnum, orientation: PaperOrientationType }) {
    const diag: Floorplan = (this.folio.diagram as Floorplan);
    const paperUnitWidth: number = convertUnits(value.format.units, DiagramUnitsAbbreviations.m, value.orientation === "portrait" ? value.format.width : value.format.height);
    const paperUnitHeight: number = convertUnits(value.format.units, DiagramUnitsAbbreviations.m, value.orientation === "portrait" ? value.format.height : value.format.width);

    const diagUnitWidth = paperUnitWidth * ScalesEnum[value.scale]
    const diagUnitHeight = paperUnitHeight * ScalesEnum[value.scale]

    const diagPixelWidth = convertUnitsToPixels(diagUnitWidth, diag.model.modelData.unitsConversionFactor)
    const diagPixelHeight = convertUnitsToPixels(diagUnitHeight, diag.model.modelData.unitsConversionFactor)

    const bounds: go.Rect = this.folio.diagram?.documentBounds as go.Rect;
    bounds.width = diagPixelWidth
    bounds.height = diagPixelHeight

    const p = bounds.position;
    const svg = this.folio.diagram?.makeSvg({
      scale: 1,
      position: new go.Point(p.x, p.y),
      size: new go.Size(bounds.width, bounds.height),
      showGrid: false,
      elementFinished: function (graphObject, SVGElement) {
        console.log(SVGElement)
      }
      // type: "image/png",
      // returnType: "string",
    });

    if (svg) {
      svg.removeAttribute('width')
      svg.removeAttribute('height')
      // svg.style.width = 'auto';
      // svg.style.height = 'auto'
      if (this.svgHolder.nativeElement.children.length)
        this.renderer.removeChild(this.svgHolder.nativeElement, this.svgHolder.nativeElement.children[0])
      this.svgHolder.nativeElement.appendChild(svg)
    } else if (this.svgHolder.nativeElement.children.length)
      this.renderer.removeChild(this.svgHolder.nativeElement, this.svgHolder.nativeElement.children[0])
  }

  print() {
    window.print()
  }

  close() {
    this.modal.hide()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
  }
}
