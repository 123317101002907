import { Component, OnInit, ViewChild } from "@angular/core";
import { FeaturesService } from "src/app/shared/services/features.service";

import * as furnitures from "src/assets/palette-furnitures.json";
// import * as elecLib from "../models/elec-lib.json";
// import * as elecLib from "../models/cpreylec-lib.json";
import { PaletteComponent } from "./palette.component";

@Component({
  selector: "all-palettes",
  templateUrl: "all-palettes.component.html",
  styleUrls: ["all-palettes.component.scss"],
})
export class AllPalettesComponent implements OnInit {
  @ViewChild("paletteFurnitures") paletteFurnitures!: PaletteComponent;

  furnituresPaletteData = (furnitures as any).default;
  elecPaletteData = FeaturesService.symbolLib$.value;//(elecLib as any).default.categories;

  tabs!: any[];

  constructor() {

  }

  selectPalette(palette: PaletteComponent) {
    palette.requestUpdate();
  }

  ngOnInit(): void {
    this.elecPaletteData = this.elecPaletteData.categories;

    this.tabs = this.elecPaletteData.map((category: any) => ({
      name: category.name,
      data: {
        nodeDataArray: category.symbols,
      },
    }));

  }
}
