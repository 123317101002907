import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ErrorPageComponent } from "./components/error-page/error-page.component";
import { FormGroupCheckboxComponent } from "./components/form-group checkbox/form-group-checkbox.component";
import { FormGroupRadioComponent } from "./components/form-group-radio/form-group-radio.component";
import { FormGroupComponent } from "./components/form-group/form-group.component";
import { ModalConfirmComponent } from "./components/modals/modal-confirm.component";
import { ModalInfoComponent } from "./components/modals/modal-info.component";
import { ModalInputComponent } from "./components/modals/modal-input.component";
import { ModalSpinnerComponent } from "./components/modals/modal-spinner.component";
import { HasFeatureDirective } from "./directive/has-feature.directive";
import { IsSuperAdminDirective } from "./directive/is-super-admin.directive";
import { IsInRolePipe } from "./pipes/roles/is-in-role.pipe";
import { NotificationService } from "./services/notify.service";
import { FeatureOptionPipe } from "./pipes/feature-value.pipe";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
  ],
  declarations: [
    ModalConfirmComponent,
    ModalInfoComponent,
    ModalInputComponent,
    ModalSpinnerComponent,
    ErrorPageComponent,

    FormGroupComponent,
    FormGroupCheckboxComponent,
    FormGroupRadioComponent,

    IsInRolePipe,
    FeatureOptionPipe,
    HasFeatureDirective,
    IsSuperAdminDirective
  ],
  providers: [NotificationService],
  exports: [
    TranslateModule,

    FormGroupComponent,
    FormGroupCheckboxComponent,
    FormGroupRadioComponent,
    ErrorPageComponent,

    IsInRolePipe,
    FeatureOptionPipe,

    HasFeatureDirective,
    IsSuperAdminDirective
  ]
})
export class SharedModule {

}
