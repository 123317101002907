import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { IProject } from '@js-elec/js-elec-types';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { take, tap } from "rxjs/operators";
import { Floorplan } from "src/app/floorPlanner/diagram/floorplan-diagram";
import getsetFunctions from "src/app/floorPlanner/inspector/getset-functions";
import { DiagramUnitsAbbreviations } from "src/app/floorPlanner/models/elements";
import { ModalConfirmComponent } from "src/app/shared/components/modals/modal-confirm.component";
import { FolioService, IOpenedFolio } from "../folio.service";

@Component({
  selector: "folio-layers",
  templateUrl: "./folio-layers.component.html",
})
export class FolioLayersComponent implements OnInit {
  @Input() project!: IProject;
  @Input() openedFolio!: IOpenedFolio;
  diagram!: Floorplan;

  form!: FormGroup;

  layers: any;
  units: any;
  constructor(
    private folioSvc: FolioService,
    private fb: FormBuilder,
    private modalSvc: BsModalService,
    private modal: BsModalRef
  ) { }

  ngOnInit() {
    this.diagram = this.openedFolio.diagram as Floorplan;
    this.layers = getsetFunctions.getLayers(this.diagram);
    this.units = getsetFunctions.getUnitsChoices();

    this.form = this.fb.group({
      layers: this.fb.array(
        this.layers.map(
          (_choice: any, index: number) => new FormControl(this.layers[index].value)
        )
      ),
      //      scale: [getScale(this.openedFolio.diagram), Validators.required],
      unit:
        new FormControl({
          value: this.openedFolio.diagram && (getsetFunctions.getUnits(this.openedFolio.diagram) || DiagramUnitsAbbreviations.m),
          disabled: true
        }, Validators.required,
        ),
      // unitsConversionFactor: [
      //   this.openedFolio.diagram && getsetFunctions.getUnitConversionFactor(this.openedFolio.diagram),
      //   Validators.required,
      // ],
      gridSize: [
        this.openedFolio.diagram && getsetFunctions.getGridSize(this.openedFolio.diagram),
        Validators.required,
      ],
      gridHide: [
        this.openedFolio.diagram && getsetFunctions.getGridHide(this.openedFolio.diagram)
      ],
      elementSize: [
        this.openedFolio.diagram && getsetFunctions.getDiagramValue(this.openedFolio.diagram, 'elementSize') * 100 || 100,
        Validators.required,
      ],
      textSize: [
        this.openedFolio.diagram && getsetFunctions.getDiagramValue(this.openedFolio.diagram, 'textSize') || 9,
        Validators.required,
      ],
    });

    // function getScale(diag: go.Diagram) {
    //   const units: string = diag.model.modelData.units;
    //   const factor = this.model.modelData.unitsConversionFactor;

    //   this.convertUnits(
    //     DiagramUnitsConversionEnum[units],
    //     DiagramUnitsConversionEnum.inchs,
    //     1
    //   );
    //   (1 / DiagramUnitsConversionEnum[units]) * 92;
    //   return 0;
    // }
  }

  getUnitAbbr(): { label: string; value: string }[] {
    return getsetFunctions.getUnitsAbbreviation(this.diagram);
  }

  save() {
    if (!this.form.valid)
      return;
    const newProps = this.form.value;
    Object.keys(newProps).forEach(prop => {
      switch (prop) {
        case 'unit':
          getsetFunctions.setUnits(this.openedFolio.diagram as Floorplan, newProps[prop])
          break;
        case 'elementSize':
          getsetFunctions.setDiagramValue(this.openedFolio.diagram as go.Diagram, 'elementSize', newProps[prop] / 100)
          break;
        case 'textSize':
          getsetFunctions.setDiagramValue(this.openedFolio.diagram as go.Diagram, 'textSize', +newProps[prop])
          break;
        case 'gridSize':
          getsetFunctions.setGridSize(this.openedFolio.diagram as Floorplan, newProps[prop])
          break;
        case 'gridHide':
          getsetFunctions.setGridHide(this.openedFolio.diagram as Floorplan, newProps[prop])
          break;
        case 'layers':
          getsetFunctions.setLayers(this.openedFolio.diagram as Floorplan, newProps[prop])
          break;

        default:
          break;
      }
    })

    // assignement is necessary (pointer value for modelData)
    Object.assign(this.openedFolio.folio.modelData, (this.openedFolio.diagram as Floorplan).model.modelData);
    this.folioSvc.updateFolio(this.project, this.openedFolio.folio.id as string, { ...this.openedFolio.folio })
      .subscribe()
    this.modal.hide()

  }

  deleteFolio() {
    this.modalSvc.show(ModalConfirmComponent, {
      initialState: {
        title: "Suppression",
        body:
          "Êtes-vous sûr de vouloir supprimer ce folio ? Cette action est irreversible.",
      },
    });
    this.modalSvc.onHide.pipe(take(1)).subscribe((r) => {
      if (r === true) {
        const index = this.folioSvc.openedFolios.indexOf(this.openedFolio);
        if (index >= 0) {
          this.folioSvc.closeFolio$.next(
            this.folioSvc.openedFolios.indexOf(this.openedFolio)
          );
        }
        this.folioSvc
          .deleteFolio(this.project, this.openedFolio.folio)
          .subscribe(() => {
            this.modal.hide();
          });
      }
    });
  }
}
