import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { LayoutModule } from "../layout/layout.module";
import { SharedModule } from "../shared/shared.module";
import { FolioLayersComponent } from "./components/folio-layers.component";
import { FolioListComponent } from "./components/folio-list.component";
import { FolioPropertiesComponent } from "./components/folio-properties.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AngularSvgIconModule,

    SharedModule,
    LayoutModule,
  ],
  declarations: [FolioListComponent, FolioPropertiesComponent, FolioLayersComponent],
  exports: [FolioListComponent, FolioPropertiesComponent],
})
export class FolioModule { }
