import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IFolio, IProject } from '@js-elec/js-elec-types';
import { BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";
import { ProjectService } from "src/app/project/project.service";
import { ModalConfirmComponent } from "src/app/shared/components/modals/modal-confirm.component";
import { FolioService, IOpenedFolio } from "../folio.service";

@Component({
  selector: "folio-list",
  templateUrl: "./folio-list.component.html",
})
export class FolioListComponent implements OnInit, OnDestroy {
  @Input() project!: IProject;
  selectedFolio$: BehaviorSubject<IOpenedFolio | null>;

  constructor(
    private projectSvc: ProjectService,
    private folioSvc: FolioService,
    private modalSvc: BsModalService,
  ) {
    this.selectedFolio$ = this.folioSvc.selectedFolio$;
  }

  ngOnInit() { }

  selectFolio(folio: IFolio) {
    this.folioSvc.addOpenedFolio(folio);
  }

  cloneFolio(folio: IFolio) {
    this.modalSvc.show(ModalConfirmComponent, { initialState: { title: "Dupliquer", body: "Le folio sera dupliqué dans votre projet." } })
    this.modalSvc.onHide.pipe(
      take(1),
      filter(r => r === true),
      switchMap(_ => this.folioSvc.addFolio(this.projectSvc.currentProject.value as IProject, {
        name: folio.name,
        modelData: folio.modelData,
        nodeDataArray: folio.nodeDataArray,
        linkDataArray: folio.linkDataArray,
      }))
    ).subscribe()

  }

  ngOnDestroy() { }
}
