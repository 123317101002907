import { FolioPropertyNames, gaineLengths } from "../folio/folio.constants";
import { Floorplan } from "./diagram/floorplan-diagram";
import getsetFunctions from "./inspector/getset-functions";
import { DiagramUnitsAbbreviations, DiagramUnitsConversionEnum, GaineLengthsEnum, LinkTypesEnum } from "./models/elements";

export const calculateLinkLength = (link: go.Link) => {
  let diag: Floorplan = link.diagram as Floorplan;
  let from = diag.findNodeForKey(link.data.from)
  let to = diag.findNodeForKey(link.data.to)
  let gaineHeight = getsetFunctions.getModelDataProperty(diag, FolioPropertyNames[FolioPropertyNames.hauteurConduitsHauts])
  const margeError = 0.10
  const margeErrorSupp = 0.5

  let height = 0;
  if (from && to)
    height = Math.abs(+from.data.deviceHeight - gaineHeight) + Math.abs(+to.data.deviceHeight - gaineHeight)
  if (isNaN(height))
    height = 0;
  height = convertUnits(DiagramUnitsAbbreviations.cm, DiagramUnitsAbbreviations.m, height);
  var numpts = link.pointsCount;
  if (numpts < 2) return "";
  let length = 0;
  for (let i = 0; i < numpts - 1; i++) {
    var p0 = link.getPoint(i);
    var pn = link.getPoint(i + 1);
    var ang = (link as any).direction;
    let distance: number = 0;
    if (isNaN(ang))
      distance = Math.floor(Math.sqrt(p0.distanceSquaredPoint(pn)));
    else {
      var rad = ang * Math.PI / 180;
      distance = Math.floor(Math.abs(Math.cos(rad) * (p0.x - pn.x)) +
        Math.abs(Math.sin(rad) * (p0.y - pn.y)));
    }
    distance = convertPixelsToUnits(distance, link.diagram?.model.modelData.unitsConversionFactor);
    length += distance
  }
  return Number((Math.round((length + height) * (1 + margeError) * 100) / 100 + margeErrorSupp).toFixed(2));
}

export const getGaineMaxLength = (gaineLength?: keyof typeof GaineLengthsEnum): number | undefined => {
  if (gaineLength) {
    const gaineLengthInfos = gaineLengths[gaineLength];
    if (gaineLengthInfos) {
      return gaineLengthInfos.length;
    }
  }
  return undefined
}

/**
   * Convert num number of pixels (document units) to units, using the adjustable conversion factor stored in modeldata
   * @param {number} num This is in document units (colloquially, if inaccurately, referred to as "pixels")
   * @return {number}
   */
export const convertPixelsToUnits = (num: number, conversionFactor: number): number => {
  // const units: string = this.model.modelData.units;
  // const factor = this.model.modelData.unitsConversionFactor;
  return num * conversionFactor;
  /*if (units === 'meters') return (num / 100) * factor;
  if (units === 'feet') return (num / 30.48) * factor;
  if (units === 'inches') return (num / 2.54) * factor;
  return num * factor; */
}

/**
 * Take a number of units, convert to cm, then divide by 2, (1px = 2cm, change this if you want to use a different paradigm)
 * @param {number} num This is in document units (colloquially, if inaccurately, referred to as "pixels")
 * @return {number}
 */
export const convertUnitsToPixels = (num: number, conversionFactor: number): number => {
  // const units: string = this.model.modelData.units;
  // const factor = this.model.modelData.unitsConversionFactor;
  return num / conversionFactor;
  /*if (units === 'meters') return (num * 100) / factor;
  if (units === 'feet') return (num * 30.48) / factor;
  if (units === 'inches') return (num * 2.54) / factor;
  return num / factor; */
}

/**
 * Convert a number of oldUnits to newUnits
 * @param {string} oldUnits cm | m | ft | in
 * @param {string} newUnits cm | m | ft | in
 * @param {number} num The number of old units to convert to new ones
 * @return {number} The number of new units
 */
export const convertUnits = (oldUnits: DiagramUnitsAbbreviations, newUnits: DiagramUnitsAbbreviations, num: number): number => {
  return (
    ((DiagramUnitsConversionEnum as any)[oldUnits] /
      (DiagramUnitsConversionEnum as any)[newUnits]) *
    (num || 1)
  );
}

const from = (alpha: string) => {
  if (!/^[A-Z]+$/.test(alpha)) {
    throw new Error('Input must be a non-empty string comprised of only characters a-z')
  }

  const letters = alpha.split('');
  let out = 0;

  for (let i = 0; i < letters.length; i++) {
    out += (letters[letters.length - 1 - i].charCodeAt(0) - 64) * Math.pow(26, i);
  }

  return out;
};


const to = (decimal: number) => {
  if (decimal <= 0) {
    throw new Error('Number must be > 0');
  }

  let out = '';

  while (true) {
    out = String.fromCharCode(((decimal - 1) % 26) + 65) + out;
    decimal = Math.floor((decimal - 1) / 26);

    if (decimal === 0) {
      break;
    }
  }

  return out;
};


export const base26Add = (alpha: string, num: number) => to(from(alpha) + num);

export const base26Subtract = (alpha: string, num: number) => to(from(alpha) - num);