import * as go from "gojs";
import { Floorplan } from "./floorplan-diagram.js";

export class ConnectorTool extends go.Tool {
  _newElement!: go.Part | null;
  _newElementData: any;
  constructor() {
    super();
    this.isEnabled = false;
  }

  public doActivate(): void {
    // go.DraggingTool.prototype.doActivate.call(this);
    console.log("activated");
    this.isActive = true;
  }

  public doMouseMove(): void {
    const fp: Floorplan = this.diagram as Floorplan;
    const mousePt: go.Point = fp.lastInput.documentPoint;
    const tool: ConnectorTool = this;
    if (tool.isActive && fp !== null && this._newElement) {
      // this._newElementData.loc = `${mousePt.x} ${mousePt.y}`;
      // console.log(mousePt.x);
      this.diagram.model.setDataProperty(
        this._newElement.data,
        "loc",
        `${mousePt.x} ${mousePt.y}`
      );
    }
  }
  public doMouseDown(): void {
    // go.DraggingTool.prototype.doMouseDown.call(this);
    this.copySelection(this.diagram, this.diagram.selection.first());
    this.diagram.isMouseCaptured = true;
  }

  // public doMouseUp(): void {
  //   //this.doDeactivate();
  // }

  public doDeactivate(): void {
    // go.DraggingTool.prototype.doDeactivate.call(this);

    this.diagram.isMouseCaptured = false;
    // this.stopTransaction();
    this.isActive = false;
    this.isEnabled = false;
    this._newElementData = null;
    this._newElement = null;
  }

  private copySelection(diagram: go.Diagram, obj: any) {
    const transactionName = "Add Element";
    diagram.startTransaction(transactionName);

    // get the node data for which the user clicked the button
    const fromNode = obj;
    const fromData = fromNode.data;
    // create a new "State" data object, positioned off to the right of the adorned Node
    const toData: any = {
      ...fromData,
      loc: undefined,
      // key: undefined,
      __gohashid: undefined,
    };
    const p = fromNode.location.copy();
    toData.loc = `${p.x + 2} ${p.y + 2}`;
    // toData.loc = go.Point.stringify(p); // the "loc" property is a string, not a Point object
    // add the new node data to the model
    const model = diagram.model;
    model.addNodeData(toData);
    this._newElementData = toData;

    // create a link data from the old node data to the new node data
    const linkdata = {
      from: model.getKeyForNodeData(fromData), // or just: fromData.id
      to: model.getKeyForNodeData(toData),
      fromPort: "logical",
      toPort: "logical",
    };
    // and add the link data to the model
    (model as any).addLinkData(linkdata);

    // select the new Node
    const newnode = diagram.findNodeForData(toData);
    (diagram as Floorplan).incrementProperty(newnode as go.Node, "name");
    diagram.select(newnode);
    this._newElement = newnode;

    diagram.commitTransaction(transactionName);
  }
}
