import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as go from "gojs";
import {
  PaletteComponent as GoPaletteComponent
} from "gojs-angular";
import { Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { ProjectService } from "src/app/project/project.service";
import * as walls from "src/assets/palette-walls.json";
import { environment } from "src/environments/environment";
import { FloorplanPalette } from "./floorplan-palette";

@Component({
  selector: "wall-palette",
  templateUrl: "./wall-palette.component.html",
})
export class WallPaletteComponent implements OnInit, OnDestroy {
  @ViewChild("palette", { static: true })
  paletteComponent!: GoPaletteComponent;

  data = { ...(walls as any).default };

  destroy$ = new Subject();

  constructor(private projectSvc: ProjectService) {
    this.projectSvc.currentProject
      .pipe(
        takeUntil(this.destroy$),
        // switchMap((project) => {
        //   const img = new Image();
        //   img.addEventListener("load", function () {
        //     alert(this.naturalWidth + ' ' + this.naturalHeight);
        //   });
        //   img.src = url;
        // })

      )
      .subscribe((project) => {
        this.data.nodeDataArray = [
          ...(walls as any).default.nodeDataArray,
          ...(project?.groundImages || []).map((_img, index) => ({
            key: "groundImg-" + index,
            category: "ground",
            source: environment.storageUrl + _img, // + '-original',
            width: 100,
            height: 60,
            imageStretch: go.GraphObject.Uniform
          })),
        ];
      });
  }

  ngOnInit() { }

  public requestUpdate() {
    this.paletteComponent.palette?.requestUpdate();
  }

  initPalette(): go.Palette {
    const fp = new FloorplanPalette();
    return fp;
    // palette.model = $(go.GraphLinksModel, {
    //   linkKeyProperty: "key", // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
    // });
  }

  paletteModelChange = function (changes: go.IncrementalData) {
    return;
    // this.paletteNodeData = DataSyncService.syncNodeData(
    //   changes,
    //   this.paletteNodeData
    // );
    // this.paletteLinkData = DataSyncService.syncLinkData(
    //   changes,
    //   this.paletteLinkData
    // );
    // this.paletteModelData = DataSyncService.syncModelData(
    //   changes,
    //   this.paletteModelData
    // );
  };

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
