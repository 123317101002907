// tslint:disable:forin
// tslint:disable:jsdoc-format
// tslint:disable:max-line-length

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { featuresList } from "@js-elec/js-elec-types";
import * as go from "gojs";
import { Floorplan } from "../diagram/floorplan-diagram";
import * as properties from "./properties.json";
// import * as squidLib from "../../palette/models/squid-lib.json";
// import * as elecLib from "../../palette/models/elec-lib.json";
// import * as elecLib from "../../palette/models/cpreylec-lib.json";
import {
  FormBuilder,
  FormControl, FormGroup, Validators
} from "@angular/forms";
import { delay } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { SymbolTypeHeightMapping } from "src/app/folio/folio.constants";
import { FolioService } from "src/app/folio/folio.service";
import { FeaturesService } from "src/app/shared/services/features.service";
import { WallReshapingTool } from "../diagram/wall-reshaping-tool";
import { calculateLinkLength, convertUnitsToPixels } from "../tools";
import getsetFunctions from "./getset-functions";
/*
 *  Copyright (C) 1998-2020 by Northwoods Software Corporation. All Rights Reserved.
 */

/**
  This class implements an inspector for GoJS model data objects.
  The constructor takes three arguments:
    {string} divid a string referencing the HTML ID of the to-be inspector's div.
    {Diagram} diagram a reference to a GoJS Diagram.
    {Object} options An optional JS Object describing options for the inspector.

  Options:
    inspectSelection {boolean} Default true, whether to automatically show and populate the Inspector
                               with the currently selected Diagram Part. If set to false, the inspector won't show anything
                               until you call Inspector.inspectObject(object) with a Part or JavaScript object as the argument.
    includesOwnProperties {boolean} Default true, whether to list all properties currently on the inspected data object.
    properties {Object} An object of string:Object pairs representing propertyName:propertyOptions.
                        Can be used to include or exclude additional properties.
    propertyModified function(propertyName, newValue) a callback
    multipleSelection {boolean} Default false, whether to allow multiple selection and change the properties of all the selected instead of
                                the single first object
    showAllProperties {boolean} Default false, whether properties that are shown with multipleSelection use the intersect of the properties when false or the union when true
                                only affects if multipleSelection is true
    showSize {number} Defaults 0, shows how many nodes are showed when selecting multiple nodes
                      when its lower than 1, it shows all nodes

  Options for properties:
    show: {boolean|function} a boolean value to show or hide the property from the inspector, or a predicate function to show conditionally.
    readOnly: {boolean|function} whether or not the property is read-only
    type: {string} a string describing the data type. Supported values: "string|number|boolean|color|arrayofnumber|point|rect|size|spot|margin|select"
    defaultValue: {*} a default value for the property. Defaults to the empty string.
    choices: {Array|function} when type == "select", the Array of choices to use or a function that returns the Array of choices.

  Example usage of Inspector:

  var inspector = new Inspector("myInspector", myDiagram,
    {
      includesOwnProperties: false,
      properties: {
        "key": { show: Inspector.showIfPresent, readOnly: true },
        "comments": { show: Inspector.showIfNode  },
        "LinkComments": { show: Inspector.showIfLink },
        "chosen": { show: Inspector.showIfNode, type: "checkbox" },
        "state": { show: Inspector.showIfNode, type: "select", choices: ["Stopped", "Parked", "Moving"] }
      }
    });
*/
@Component({
  selector: "data-inspector",
  templateUrl: "./data-inspector.component.html",
  styleUrls: ["./data-inspector.component.scss"],
})
export class DataInspectorComponent implements OnChanges {
  @Input() diagram!: Floorplan;

  propertiesSchema: {
    [K in string]: { [J in string]: { [L in string]: any } };
  };
  inspectedObject: go.Part | null = null;
  inspectedProperties: any[] | null = null;

  form!: FormGroup;
  swappingSymbols?: string[] = undefined;
  nameChoices?: string[];

  constructor(
    private fb: FormBuilder,
    private folioSvc: FolioService,
    private authSvc: AuthService,
  ) {
    this.propertiesSchema = (properties as any).default;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.diagram.currentValue === undefined ||
      !changes.diagram.currentValue.addDiagramListener
    )
      return;

    // this.diagram.addModelChangedListener((e) => {
    //   if (e.isTransactionFinished) this.inspectObject();
    // });

    this.diagram.addDiagramListener("ChangedSelection", (e) => {
      this.inspectObject();
    });
    this.inspectObject();
  }

  /**
   * Update the HTML state of this Inspector given the properties of the {@link #inspectedObject}.
   * @param {Object} object is an optional argument, used when {@link #inspectSelection} is false to
   *                        set {@link #inspectedObject} and show and edit that object's properties.
   */
  inspectObject(obj?: any) {
    this.inspectedObject =
      obj || (this.diagram.selection && this.diagram.selection.first());
    if (!this.inspectedObject) return;
    // if (!this.inspectedObject) {
    //   this.inspectedObject = ({
    //     data: { ...this.diagram.model.modelData, category: "diagram" },
    //     diagram: this.diagram,
    //   } as unknown) as go.Part;
    // }
    const inspectedCategory = this.inspectedObject.type.name === 'Link' ? 'link' : this.inspectedObject.data.category;
    if (this.propertiesSchema[inspectedCategory]) {
      if (inspectedCategory === 'link') {
        const length = calculateLinkLength(this.inspectedObject as go.Link)
        if (length)
          getsetFunctions.setValue(this.inspectedObject, { property: 'length' }, Math.round(length * 100) / 100)
      } else {
        this.getSwappingSymbols();
        this.getNameChoices();
      }

      this.inspectedProperties = Object.keys(
        this.propertiesSchema[inspectedCategory]
      )
        .map(
          (k) =>
          ({
            ...this.propertiesSchema[inspectedCategory][k],
            property: k,
          } as any)
        )
        .sort((a, b) => {
          return a.order > b.order ? 1 : -1;
        });

      this.form = this.buildElementProperiesForm();
    } else {
      this.inspectedProperties = null;
      this.form = this.fb.group({});
    }
  }

  buildElementProperiesForm() {
    const form = this.fb.group(
      this.inspectedProperties?.reduce((acc, prop) => {
        const getterFc = (getsetFunctions as any)[prop.getValue || "getValue"];
        const setterFc = (getsetFunctions as any)[prop.setValue || "setValue"];

        if (prop.type === "scale") {
          const links = this.diagram.dimensionLinks;
          if (links && this.inspectedObject) {
            let scaleLength = this.inspectedObject.data.scaleLength;
            if (!scaleLength || scaleLength === '0.00')
              scaleLength = getterFc(this.inspectedObject, prop);

            acc[prop.property] = new FormControl({
              value: typeof scaleLength === 'string' ? scaleLength.replace(/[a-z]*$/i, '') : scaleLength,
              disabled: true,
            })
          }
        } else if (
          prop.type === "select" &&
          prop.expanded === true &&
          prop.multiple === true
        ) {
          const choices =
            typeof prop.choices === "string"
              ? (getsetFunctions as any)[prop.choices](this.inspectedObject, prop)
              : prop.choices;
          const values = getterFc(this.inspectedObject, prop);

          acc[prop.property] = this.fb.array(
            choices.map(
              (_choice: any, index: number) => new FormControl(values[index].value)
            )
          );
        } else {
          const defaultVal = getterFc(this.inspectedObject, prop)

          acc[prop.property] = new FormControl(
            {
              value: defaultVal !== undefined ? defaultVal : prop.default,
              disabled: prop.readOnly === true,
            },
            prop.required === true ? Validators.required : undefined
          );
        }
        acc[prop.property].valueChanges.pipe(delay(600)).subscribe((val: any) => {
          setterFc(this.inspectedObject, prop, val);
          if (prop.refreshAfterChanged === true) {
            if (this.inspectedObject?.data.category === "WallGroup") {
              // force updating thickness
              const tool: WallReshapingTool = this.inspectedObject.diagram?.toolManager.mouseDownTools.toArray()[3] as WallReshapingTool;
              tool.performMiteringOnWall(this.inspectedObject as go.Group);
              (this.inspectedObject.diagram as Floorplan).updateWall(
                this.inspectedObject as go.Group
              );
            }
            this.inspectObject();
          }
        });
        return acc;
      }, {})
    );

    return form;
  }

  updatePartData(obj: go.Part, props: any[], data: any) {
    this.diagram.startTransaction("set all properties");
    props.forEach((p) => {
      if (data[p.property] === undefined) return;
      if (p.readOnly === true) return;
      this.diagram.model.setDataProperty(
        obj.data,
        p.property,
        data[p.property]
      );
    });
    this.diagram.commitTransaction("set all properties");
  }

  scaling(formControl: FormControl, prop: any) {
    if (formControl.enabled && this.inspectedObject) {
      formControl.disable();
      // const link: go.Link = this.diagram.dimensionLinks.toArray()[0];
      const getterFc = (getsetFunctions as any)[prop.getValue || "getValue"];

      (this.diagram as Floorplan).changeUnitsConversion(
        (formControl.value / convertUnitsToPixels(getterFc(this.inspectedObject, prop), this.diagram.model.modelData.unitsConversionFactor)).toFixed(6),
        this.diagram.model.modelData.gridSize,
        false
      );
      getsetFunctions.setValue(this.inspectedObject, { property: 'scaleLength' }, formControl.value + 'm');
      this.inspectObject();
    } else formControl.enable();
  }

  getSwappingSymbols() {
    if (this.authSvc.currentAuth.value && !FeaturesService.hasFeatureAccess(featuresList.squidSwapping, this.authSvc.currentAuth.value?.user)) {
      this.swappingSymbols = undefined;
      return;
    }

    const squidLib = FeaturesService.squidLib$.value;
    const swapping = squidLib.symbolsSwapping.find((swapping: any) => {
      return swapping.find((symbol: any) => symbol === this.inspectedObject?.data.symbolName)
    })
    if (swapping && swapping.length) {
      this.swappingSymbols = swapping;
    } else {
      this.swappingSymbols = undefined;
    }
  }

  getNameChoices(): void {
    if (!FeaturesService.isFeatureEnabled(featuresList.squid)) {
      this.nameChoices = undefined;
      return;
    }

    const squidLib = FeaturesService.squidLib$.value;
    const foundComponent = this.folioSvc.findComponentInTree(this.inspectedObject?.data.key)
    if (foundComponent && foundComponent.type) {
      const labelChoices = squidLib.labelChoices
        .find((labelChoices: any) =>
          labelChoices.symbols.indexOf(foundComponent.type) >= 0
        );
      if (!labelChoices)
        this.nameChoices = undefined;
      else {
        this.nameChoices = labelChoices.choices;
      }
    }
  }

  symbolChanged(event: any) {
    const elecLib = FeaturesService.symbolLib$.value;
    const selection = event.currentTarget.value;
    const symbol = elecLib.categories.flatMap((cat: any) => cat.symbols).find((s: any) => s.symbolName.toLocaleLowerCase() === selection.toLocaleLowerCase())
    if (symbol && this.inspectedObject) {
      getsetFunctions.setValue(this.inspectedObject, { property: "type" }, symbol.type)
      const deviceHeightControl = this.form.get('deviceHeight');
      if (deviceHeightControl) {
        deviceHeightControl.setValue(this.diagram.model.modelData.properties[(SymbolTypeHeightMapping as any)[symbol.symbolName]] || symbol.deviceHeight || deviceHeightControl.value);
        getsetFunctions.setValue(this.inspectedObject, { property: "deviceHeight" }, deviceHeightControl.value)
      }
      // getsetFunctions.setValue(this.inspectedObject, { property: "deviceHeight" }, symbol.deviceHeight || this.diagram.model.modelData.properties[(SymbolTypeHeightMapping as any)[symbol.symbolName]])
      getsetFunctions.setValue(this.inspectedObject, { property: "geo" }, symbol.geo)
      getsetFunctions.setValue(this.inspectedObject, { property: "width" }, symbol.width)
      getsetFunctions.setValue(this.inspectedObject, { property: "height" }, symbol.height)
      getsetFunctions.setValue(this.inspectedObject, { property: "symbolName" }, symbol.symbolName)
    }

    this.getNameChoices();
  }


}
