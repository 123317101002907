import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IProject } from '@js-elec/js-elec-types';
import { BsModalService } from "ngx-bootstrap/modal";
import { BehaviorSubject, Observable } from "rxjs";
import { take, tap, timeout } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { Floorplan } from "../floorPlanner/diagram/floorplan-diagram";
import { WallThicknessEnum } from "../folio/folio.constants";
import { FolioService, IOpenedFolio } from "../folio/folio.service";
import { ProjectCreateComponent } from "../project/components/project-create.component";
import { ProjectService } from "../project/project.service";
import { ModalConfirmComponent } from "../shared/components/modals/modal-confirm.component";
import { NormeModalComponent } from "./components/norme-modal.component";

@Component({
  selector: "editor-layout",
  templateUrl: "editor.component.html",
  styleUrls: ["editor.component.scss"],
})
export class EditorComponent implements OnInit {
  project$: Observable<IProject | null>;
  openedFolios: IOpenedFolio[];
  selectedFolioIndex = 0;

  tabIndex = 0;
  showWallMenu = false;

  model!: {
    modelData: any;
    nodeDataArray: any[];
    linkDataArray: any[];
  };
  diag = new BehaviorSubject<Floorplan | null>(null);

  wallPaletteInitialized = false;
  wallMode?: WallThicknessEnum = undefined;
  wallModes = WallThicknessEnum

  constructor(
    private projectSvc: ProjectService,
    public authSvc: AuthService,
    public folioSvc: FolioService,
    private modalSvc: BsModalService,
    private router: Router
  ) {
    this.project$ = this.projectSvc.currentProject;
    this.openedFolios = this.folioSvc.openedFolios;

    this.folioSvc.openFolio$.subscribe(
      (folio) =>
      (this.selectedFolioIndex = this.openedFolios.findIndex(
        (f) => f.folio.id === folio.id
      ))
    );
    this.folioSvc.closeFolio$.subscribe((index) => {
      this.selectedFolioIndex = Math.max(-1, index - 1);
      if (this.openedFolios.length === 0) {
        this.tabIndex = 0;
      }
    });
  }

  ngOnInit() {
    this.selectWallMode();
  }

  selectTab(index: number) {
    if (this.openedFolios.length === 0) {
      return;
    }
    if (index === 1) {
      this.selectWallMode();
    }
    else if (index === 2) {
      this.selectWallMode();
    }
    else if (index === 3) {
      this.wallPaletteInitialized = true;
    }
    this.tabIndex = index;
  }

  closeFolio(index: number) {
    this.folioSvc.removeOpenedFolio(index);
  }

  selectFolio(index: number) {
    this.selectedFolioIndex = index;
    this.folioSvc.selectedFolio$.next(this.openedFolios[index]);
  }

  selectWallMode(wallMode?: WallThicknessEnum) {

    setTimeout(() => this.showWallMenu = false, 400)
    this.wallMode = wallMode;
    this.folioSvc.wallModeActivated$.next(this.wallMode);
  }

  openProject() {
    this.router.navigate(["/", "editor"]);
  }

  newProject() {
    this.modalSvc.show(ProjectCreateComponent, {});
    this.modalSvc.onHide.pipe(take(1)).subscribe((p) => {
      window.location = window.location;
    });
  }

  openNorme() {
    this.modalSvc.show(NormeModalComponent, { class: 'right-modal' })
  }

  deleteProject() {
    this.modalSvc.show(ModalConfirmComponent, {
      initialState: {
        title: "Suppression",
        body:
          "Êtes-vous sûr de vouloir supprimer le dossier et tous ces folios ? Cette opération est irreversible.",
      },
    });

    this.modalSvc.onHide.pipe(take(1)).subscribe((result) => {
      if (result === true) {
        this.projectSvc
          .deleteProject(this.projectSvc.currentProject.value?.id as string)
          .pipe(
            tap(_ => this.openProject())
          ).subscribe();
      }
    });
  }
}
