import { Component, OnInit, Input } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "modal-info",
  templateUrl: "./modal-info.component.html",
})
export class ModalInfoComponent implements OnInit {
  constructor(public activeModal: BsModalRef) { }
  @Input() title!: string;
  @Input() body!: string;
  @Input() buttonLabel = "Ok";

  ngOnInit() { }
}
